<template>
  <div class="container">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <h1>Terms of Use and Privacy Policy</h1>

            <div class="my-5">
              <div class="mb-2">
                <router-link
                    :to="{ name: 'EN_TermsAndConditions', hash: '#pogoji-uporabe' }"
                >Terms of Use
                </router-link
                >
              </div>
              <div>
                <router-link
                    :to="{
                    name: 'EN_TermsAndConditions',
                    hash: '#pravilnik-o-varstvu-osebnih-podatkov'
                  }"
                >Privacy Policy
                </router-link
                >
              </div>
            </div>

            <h2 id="pogoji-uporabe" name="pogoji-uporabe">TERMS OF USE</h2>
            <h3>Preamble</h3>
            <p>
              Thank you for registering to the 'Zapisi spomina' application. These Terms of Use set out the rules and
              restrictions that apply to your use of the App, in particular when uploading content. Please read these
              terms carefully before you start using the app. For any questions, please contact the Dobra pot Institute
              (info@dobra-pot.si).
            </p>
            <p>
              These Terms of Use constitute a binding agreement governing the use of the 'Zapisi spomina' App between
              you and the Dobra pot Institute as the author, owner and manager of the App and the data.
            </p>
            <p>
              The Agreement enters into force when you receive the confirmation email in your inbox about your
              registration to the App. Your registration means that you agree to these Terms of Use and enter into an
              agreement with the Dobra pot Institute.
            </p>
            <p>For registered users who upload content into the app, acceptance of these Terms of Use constitutes an
              agreement of voluntary cooperation between the Dobra pot Institute and the user as a volunteer.</p>
            <p>
              The main purpose of the application is to record the living oral tradition of people, especially the
              elderly, and their memories of life in the past. The content is recorded with a view to preserving it for
              today and tomorrow.
            </p>

            <h3>ARTICLE 1 - Users, registration, password</h3>
            <p>
              Registration in the Memory Records application is free and voluntary. Simply reading the content on the
              app does not require registration. By registering, registered users are given the possibility to publish
              content on the app.
            </p>
            <p>
              To register, users must provide a valid email address, first and last name, pseudonym or display name,
              year of birth and password, optionally also a description and a telephone number.
            </p>
            <p>
              If the user is a minor, he/she must in addition to his/her valid e-mail address, first and last name,
              pseudonym or alias, year of birth and password also provide the name of a legal representative (parent or
              guardian) who consents to his or her registration as well as his/her valid e-mail address.</p>
            <p>
              A user profile in the application can be created on behalf of an organization, association, institution,
              etc. Such users are equally subject to all the rules and conditions set out in this document, with the
              addition that the person who creates a user account on behalf of an organization, association,
              institution, etc. must have the consent or authorization of the legal representative of that organization,
              association, institution, etc. to do so.
            </p>
            <p>
              The User undertakes to provide accurate, complete and up-to-date information about himself/herself at the
              time of registration. The User may not use a display name or username that he/she does not have the right
              to use or the name of another person with the intention of imitating another person. A user account in the
              App is not transferrable.
            </p>
            <p>
              The password is linked to the individual user, it is not transferable and must be protected by the user
              against access by third parties and thus against misuse.
            </p>
            <p>
              By registering, the user agrees to the use of his/her personal data provided for the purposes of managing
              the application and the content therein.
            </p>
            <p>
              By registering in the application, the user agrees to receive notifications to his/her e-mail address
              about any change in the status of his/her record in the application. These notifications can be
              deactivated at any time, if desired, in the settings of the user account.
            </p>
            <p>
              By registering in the application, the user also agrees to his/her email address being added to the list
              of recipients of newsletters about other activities of the Dobra pot Institute. The user may unsubscribe
              from receiving these notifications by clicking the relevant link (Unsubscribe) at the bottom of each
              email.
            </p>

            <h3>ARTICLE 2 - Dedicated use</h3>
            <p>
              The main purpose of the 'Zapisi spomina' application is to record the living oral tradition of people,
              especially the elderly, and their memories of their lives in the past. The content is recorded in order to
              preserve it for today and the future.
            </p>
            <p>
              Registered users who upload content into the application must do so in accordance with the main purpose of
              the application, with respect for the tradition itself and for the bearers of tradition, as well as for
              other readers/users of the application.
            </p>
            <p>
              The App may not host content intended to promote a particular legal entity or natural person, to attract
              customers, to offer its services, products, etc.
            </p>
            <p>
              Each user is responsible for his/her own actions and posts. The owner and manager of the application, the
              Dobra pot Institute, is in no way responsible for the published content in the application.
            </p>
            <p>
              The user is responsible for the truthfulness and ethnological relevance of the content published on the
              app.
            </p>
            <p>
              All user entries are sent to the manager for review and approval upon submission. The Dobra pot Institute
              reserves the right to moderate or delete user entries at its sole discretion.
            </p>

            <h3>ARTICLE 3 - User entries - posts</h3>
            <p>
              The titles of posts or records shall reflect their actual content as clearly as possible.
            </p>
            <p>
              Each post should be allocated to the appropriate category and subcategory as defined in the application
              and in accordance with the Rules on the Registry of Types of Heritage and Protection Guidelines (OJ of the
              Republic of Slovenia, Nr. 102/10). When classifying topics in the appropriate category, the user should
              read the descriptions of each category in the application in order to classify his/her content
              appropriately. </p>
            <p>
              If desired, the user can add keywords (keywords, tags) to his/her post.
            </p>
            <p>The use of capital letters only (shouting) is prohibited.</p>
            <p>Posts in the app must be in Slovene.</p>
            <p>
              Material uploaded to or published by the user in the application are so called 'user posts'. These may
              include text, photos, videos, audio clips, links to other pages, etc.
            </p>
            <p>
              In order for the manager of the application to use these records and make them available for viewing to
              other users (registered and non-registered), the author of the post grants the manager the copyright
              attached to the record, namely:
            </p>
            <p>
              a perpetual, non-exclusive, transferable, territorially unlimited, sub-licensable and royalty-free license
              - this license/permission allows the storage, display, publication, modification (alteration), translation
              of the content entered and other activities consistent with the purpose of the application. The manager
              may use this content to promote the application and its other activities in the field of cultural
              heritage, reproduce it, print it, transmit it to others, include it in further projects and
              collaborations, disseminate it as it sees fit, etc.
            </p>
            <p>
              Once the content entered by the user has been validated by the manager and therefore the post has been
              made public, the user can no longer delete this content, this right being reserved by the manager of the
              app.
            </p>

            <h3>ARTICLE 4 - Prohibited Content, Rejection, Amendment</h3>
            <p>
              Posts that are offensive, inappropriate, contain profanity, incite hatred, discrimination or disparage
              individuals or groups on the basis of their race, ethnicity, religion, disability, age, nationality,
              status, sexual orientation, gender, gender identity or other characteristics associated with systemic
              discrimination or marginalisation are prohibited.
            </p>
            <p>
              The appropriateness or inappropriateness of a post shall be a assesed by the manager, who may refuse to
              publish content its team considers inappropriate.
            </p>
            <p>
              Similarly, the manager may reject content that is incomplete, does not demonstrate coherence, identity,
              readability, meaningfulness or factual truthfulness.
            </p>
            <p>
              The manager shall decide independently which post shall be rejected in full and which shall be sent back
              to the author for completion or correction. In the latter cases, the manager shall contact the author by
              telephone (if provided) or e-mail.
            </p>
            <p>
              The manager has the right to moderate or completely remove any content that does not comply with these
              rules.
            </p>
            <p>
              The manager may block a user name or user and deny access to his/her account if he/she intentionally or
              repeatedly violates these terms of use.
            </p>
            <p>
              Advertising for the purpose of selling products and services in the App is prohibited.
            </p>
            <p>
              The Application may not be used by a person with whom the Agreement has been terminated or the account
              terminated in the past for violation of the Terms of Use.
            </p>
            <p>
              Similarly, the App may not be used by any person who is prohibited from using the App by applicable law.
            </p>
            <p>
              The App shall not be used to engage in action or post content that:
            </p>
            <ul>
              <li>
                violates these Terms and other terms and policies applicable to the use of the App;
              </li>
              <li>is unlawful, misleading, discriminatory or fraudulent;</li>
              <li>
                infringes or violates the rights of others, including intellectual property rights.
              </li>
            </ul>

            <p>
              It is prohibited to upload viruses or other malicious software to the App or to do anything that may
              disable, overload or interfere with the proper functioning and appearance of the App.
            </p>
            <p>
              The manager has the right to remove or restrict access to a User's content, services or information if its
              team determines that such action is reasonably necessary to remedy or mitigate any adverse legal or
              regulatory consequences for the Dobra pot Institute.
            </p>

            <h3>ARTICLE 5 - Copyright and consents for the use of data</h3>
            <p>
              If the User uploads photographs to the Application, he/she acknowledges that he/she has the copyright to
              publish the photographs, in the sense that he/she is the author of the photographs or, if he/she is not
              the author, that he/she has the author's permission to upload and publish the photographs in the
              Application.
            </p>
            <p>
              If the user enters information about or memories of deceased persons, he/she shall observe their right to
              reverence, respect and remembrance and shall guarantee that he/she has the permission by the heirs or next
              of kin of the deceased person to do so.
            </p>
            <p>
              If the user includes representative, symbolic photographs in the post, this shall be indicated in the
              record to avoid misleading the viewers.
            </p>
            <p>
              The user may insert hyperlinks to other documents, photographs and recordings. In this case, the user
              guarantees that he/she has the author's permission to insert the hyperlink.
            </p>
            <p>
              If the user chooses to enter an extended set of ethnologically relevant information about the bearer of
              the tradition (him-/herself or another person) for the purpose of creating a more detailed ethnological
              record, he/she warrants that he/she has the permission of the relevant person for the entry of such
              information/photographs/sound recordings/videos/documents.
            </p>
            <p>
              The same applies to the publication of music - it can only be uploaded to the app with the explicit
              permission of the author(s). By uploading music, the user guarantees that he/she has all the relevant
              consents from the author(s) of the music.
            </p>
            <p>
              If any copyright infringement or personal data protection infringement is found, the user who caused the
              infringement is solely responsible.
            </p>
            <p>
              If the manager of the application is informed of a suspected copyright or data protection infringement,
              the editors shall remove the content in question without prior notice to the user.
            </p>

            <h3>ARTICLE 6 - Restrictions on the use of intellectual property</h3>
            <p>
              If the User uses content, which is the intellectual property of the Dobra pot Institute (for example,
              images, designs, videos and sounds), the Dobra pot Institute retains all rights to that content (but not
              to the User's content).
            </p>
            <p>
              If a user shares content that is published in the app on other websites, social networks, etc., they must
              do so using dedicated sharing 'buttons' or by sharing the full link (url address) to preserve the link to
              the original content. Content may not be shared in any other way.
            </p>

            <h3>ARTICLE 7 - Discount</h3>
            <p>
              The project partner, namely the Slovene Ethnographic Museum (SEM), offers registered users of the app a
              50% discount on a single admission ticket to visit the SEM exhibitions in 2021. The discount is obtained
              by presenting a 'certificate of a registered user' at the SEM reception (the certificate is available to
              each registered user in the "My profile" tab).
            </p>

            <h3>ARTICLE 8 - Modification of the Terms of Use</h3>
            <p>
              This Agreement is valid for an indefinite period of time. The user may at any time cease to use the
              application. The User may at any time terminate this Agreement hby communicating his/her wish of
              termination of his/her account to the email address of the Application manager (info@dobra-pot.si).
            </p>
            <p>
              The agreement with the User shall be deemed terminated if the User does not give his/her consent to
              amendments and modifications to these Terms of Use.
            </p>
            <p>
              The Dobra pot Institute reserves the right to terminate this Agreement and disable access to the User
              account if there is a breach of these Terms of Use, if the User infringes the intellectual property rights
              of others or if a termination of the Agreement is necessary for other legal reasons.
              The Dobra pot Institute shall notify the User of the date of termination and the reasons for it.
            </p>

            <h3>9. ČLEN - Obdobje veljavnosti, prekinitev sporazuma</h3>
            <p>Ta sporazum je sklenjen za nedoločen čas.</p>
            <p>
              Z uporabo aplikacije lahko uporabnik kadar koli preneha. Uporabnik
              lahko kadar koli prekine pogodbo po lastni volji tako, da željo po
              ukinitvi svojega računa sporoči na elektronski naslov upravljavca
              aplikacije (info@dobra-pot.si).
            </p>
            <p>
              Šteje se, da je sporazum z uporabnikom prenehal, če uporabnik ne
              poda svojega soglasja k spremembam in dopolnitvam teh Pogojev
              uporabe.
            </p>
            <p>
              Zavod Dobra pot si pridržuje pravico, da prekine ta sporazum in
              onemogoči dostop do uporabnikovega računa, če pride do kršitve teh
              Pogojev uporabe in če uporabnik krši pravice intelektualne
              lastnine drugih oseb ali je prekinitev sporazuma potrebna iz
              pravnih razlogov.
            </p>
            <p>
              O datumu in razlogih prekinitve Zavod Dobra pot obvesti
              uporabnika.
            </p>

            <h3>ARTICLE 10 - Limitation of liability</h3>
            <p>
              The Dobra pot Institute excludes any liability to the User for 1) the use of the Services, including any
              downtime or periods when the Application is unavailable 2) information and content uploaded into the
              Application by other Users 3) the manner in which the Application is used by other Users, 4) any indirect,
              consequential or special damage.
            </p>

            <h3>ARTICLE 11 - Electronic communication</h3>
            <p>
              Notifications and warnings concerning the use of the Application shall be sent in text form by email, and,
              where appropriate, by telephone (provided that the user provides a telephone number).
            </p>

            <h3>ARTICLE 12 - Dispute resolution</h3>
            <p>
              The User and the app manager shall endeavor to resolve any misunderstandings and disputes arising out of
              this Agreement amicably and in case this is not possible, the disputes shall be settled by a court of
              competent jurisdiction.
            </p>

            <h3>ARTICLE 13 - Final Provisions</h3>
            <p>
              1. Should any provision in these Terms of Use prove to be invalid, void or unenforceable for any reason,
              all other provisions shall remain in full force and effect.
            </p>
            <p>
              2. The provisions of the Copyright and Related Rights Act, the Obligations Code and other applicable
              legislation of the Republic of Slovenia apply to the interpretation of the provisions in these Terms of
              Use.
            </p>

            <div class="mt-5">&nbsp;</div>
            <div class="mt-5">&nbsp;</div>
            <h2
                class="mt-5"
                id="pravilnik-o-varstvu-osebnih-podatkov"
                name="pravilnik-o-varstvu-osebnih-podatkov"
            >
              PERSONAL INFORMATION PROTECTION POLICY
            </h2>

            <h3>1. Data manager and contact details</h3>
            <p>
              This policy applies to the processing (use) of any personal data by the Dobra pot Institute (the
              "manager") for the purposes of administering the 'Zapisi spomina' app or any other app operated on behalf
              of the manager.
            </p>
            <p>Information about the manager:</p>
            <p>Dobra pot Institute, institute for culture and sustainable action</p>
            <p>Headquarters: Barka 31, 6217 Vremski Britof, Slovenia</p>
            <p>Business address: Partizanska cesta 33b, 6210 Sežana, Slovenia</p>
            <p>Tax number: 10740309 (not subject to VAT)</p>
            <p>Registration number: 6824030000</p>
            <p>E-mail: info@dobra-pot.si</p>


            <h3>2. What types of user data are being collected and processed?</h3>
            <p>We collect the following data:</p>
            <ul>
              <li>o first and last name</li>
              <li>display name or pseudonym</li>
              <li>year of birth</li>
              <li>the name and surname of the legal representative of a minor</li>
              <li>the e-mail address of the legal representative of a minor</li>
              <li>telephone number (optional and if entered by the user)</li>
              <li>e-mail address</li>
              <li>profile picture (if uploaded by user, optional)</li>
              <li>possibly also: address, occupation/education, house name, region, region of origin, description of
                where he/she got the information (if the user provides this information in his/her post)
              </li>
              <li>data on the use of the application (clicks on links, time spent) and data on the response to emails
                (whether the email was opened, which links were clicked);
              </li>
            </ul>

            <p>
              The app manger also collects email addresses of users who sign up to receive newsletters on cultural
              heritage. The newsletters by the Institute Dobra pot do not include direct marketing elements, but are
              rather used to inform the subscribers and users about the organization's activities and projects, its work
              in the field of cultural heritage. Individuals may at any time unsubscribe from the newsletter.
            </p>

            <p>
              In addition to user data, the app operator collects and stores data on the memory holder (who may be a
              different person than). This data is stored in the archives of the operator and is generally not made
              public unless the author/user discloses it in the post (in the text). An exception to this is the region
              the post stems from, which is publicly displayed in all posts.
            </p>
            <p>
              The data on the memory holder are kept in the manager's archive for the purpose of potential use in expert
              analyses and studies and for the purpose of verifying the authenticity of the stories. The data may be
              made available to scientists, ethnologists and other experts for the purpose of carrying out historical or
              ethnological research or research in the field of cultural heritage serving the greater good of society,
              the progress of science and the understanding of social history, technological progress or public
              interest.
            </p>
            <p>
              If the manager decides to share data from the archives with the above-mentioned parties, the manager shall
              verify the data and sign a contract with the experts specifying the exact scope and purpose of the data
              use.
            </p>

            <h3>
              3. Voluntary provision of data and consequences of non-provision
            </h3>
            <p>
              The provision of personal data is voluntary. The user is not obliged to provide personal data, but if
              he/she does not provide it, he/she cannot enter into an agreement with the Institute Dobra pot (and
              consequently use the application).
            </p>

            <h3>
              4. Which user data is publicly visible in the app and which not?
            </h3>
            <p>The following information about the user is visible to the public:</p>
            <ul>
              <li>o display name or pseudonym</li>
              <li>profile picture (if uploaded by the user)</li>
              <li>user description (if provided by the user)</li>
            </ul>

            <h3>5. How and with whom is the information shared?</h3>

            <p>a. Suppliers</p>
            <p>
              Data and content is provided to suppliers and service providers who support our operations, for example by
              providing technical and software services, user analysis, legal advice, sending of newsletters, surveys
              and similar services.
            </p>
            <p>b. Researchers, ethnologists, professional institutions</p>
            <p>
              Data and content is provided to researchers, academics and professional institutions for the purpose of
              implementing ethnological or cultural heritage research for the greater good, technological progress,
              public interest, health and well-being.
            </p>
            <p>c. Law enforcement authorities or legal requirements</p>
            <p>
              When necessary, information is disclosed to law enforcement authorities in order to meet legal
              requirements.</p>
            <p>The above listed entities that process data may only process personal data in the context of instructions
              and may not process personal data for any other purpose. They, together with their employees, are obliged
              to protect the confidentiality of personal data.</p>
            <p>The above listed entities do not export data to third countries (outside the EEA Member States - i.e. EU
              Member States plus Iceland, Norway and Liechtenstein).</p>

            <h3>6. Legal basis for data processing</h3>
            <p>
              Personal data is processed on the following legal bases: the Regulation (EU) 2016/679 of the European
              Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the
              processing of personal data and on the free movement of such data (hereinafter: General Data Protection
              Regulation, GDPR) and the Slovene Personal Data Protection Act (ZVOP-1-UPB1, OJ RS No. 94/2007), and the
              Personal Data Protection Policy of the Dobra pot Institute.
            </p>
            <h3>
              7. How do we respond to legal requests or prevent damage?
            </h3>
            <p>
              We only access, store and disclose personal data to users who provide the appropriate legal basis or
              submit a written request or consent by the data subject.
            </p>
            <h3>
              8. How can you exercise your rights under the General Data Protection Regulation?
            </h3>
            <p>
              Under the General Data Protection Regulation, you have the right to access, rectify, transfer and delete
              your data and request your data to be deleted. You also have the right to object to and restrict certain
              types of data processing. You can exercise these rights by contacting info@dobra-pot.si. This includes:
            </p>
            <p>
              • the right to object to the processing of your data in the framework of the institute's activities
              carried out in the public interest or in the pursuit of the institute's legitimate interests or the
              interests of third parties.
            </p>
            <h3>9. Data retention</h3>
            <p>
              Data is retained for as long as necessary for the operation of the app.
            </p>
            <h3 id="politika-piskotkov"
                name="politika-piskotkov">10. Cookie Policy</h3>
            <p>
              <b>Notice on the use of cookies</b><br/>
              This notice is in line with the Slovene Electronic Communications Act (ZEKom-1), which regulates the use
              of cookies and similar technologies to store data or gain access to data stored on a user's computer,
              smartphone or other device.
            </p>

            <p>
              <b>What are cookies?</b><br/>
              Cookies are small text files an application stores into your computer or mobile device when you access a
              website.<br/>
              What is the purpose of cookies?<br/>
              Cookies are used to store information about your use of the Zapisi spomina app, for example, about your
              last visit at the app's website, the type of information you were looking for there. These files can only
              be read by our app and cannot be accessed by other parties.<br/>
              This website uses Google Analytics, a web analytics service provided by Google, Inc.. The data generated
              by this cookie when you use this website (including your IP address) will be sent to Google's servers and
              stored there. Google will use this information to evaluate your use of the website, to make reports on
              website activity for website operators and to provide other services related to the website's activity and
              internet use. Google may transfer this information to third parties if required by law or if third parties
              process this information on Google's behalf. Google will never associate your IP address with other data
              held by Google. By using this website, you authorize Google to process your data in the manner and for the
              purposes set out above.<br/>
              When using cookies, the application manager does not store and process user personal data. Neither does
              the manager use electronic communication networks to store personal data or obtain access to personal
              data. The app manager technically stores data and accesses it solely for the purpose of performing or
              facilitating the transmission of a message through an electronic network and ensuring the operation of the
              app, i.e. a user-friendly experience. The app manager uses session cookies to track the user while
              browsing the app. Session cookies are deleted from the user's computer when the user closes the browser.
              Session cookies are created temporarily and are deleted when the user closes the browser window.
              Persistent or stored cookies are cookies that remain stored on the user's device. Such cookies are
              activated every time the user visits the website that created that particular cookie.</p>


            <p><b>Which cookies are being used?</b><br/>
              All cookies used by the app's website are essential for the app to function. They are the
              following:<br/><br/>
              <b>_ga and _ga_E0NS1DBKZV</b>:<br/>
              web analytics using Google Universal Analytics. Cookie used to track visitors. Google Analytics identifies
              users with a unique identifier called a "client id". This ID is stored in the _ga cookie along with other
              parameters.<br/><br/>
              <b>PH_HPXY_CHECK</b>;<br/>
              cookie used by the security system to prevent brute force attacks and does not allow the identification of
              individual users. It lasts until the end of the session (closing the browser). It is used for visitor
              tracking, grouping of individual user behavior<br/> <br/>
              <b>_gid;</b><br/>
              web analytics using Google Universal Analytics. Cookie used to group user behavior for each user.
              https://policies.google.com/<br/><br/>
              To identify a user on YouTube:<br/>
              <b>YSC<br/>
                VISITOR_INFO1_LIVE<br/>
                PREF<br/>
                GEUP</b><br/><br/>

              For hosting multimedia content on Dropbox:<br/>
              <b>__Host-ss<br/>
                __Host-js_csrf<br/>
                uc_session<br/>
                gvc<br/>
                locale<br/>
                t<br/>
              </b>
            </p>
            <p>
              <b>Cookie settings</b><br/>
              Users can independently decide whether to enable the use of cookies. You can control and change your
              cookie settings in your web browser. If you do not accept cookies, you will not be able to view the
              content of the 'Zapisi spomina' app.
            </p>
          </div>
        </article>
        <br/><br/><br/><br/><br/><br/><br/><br/>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";

import Sidebar from "@/components/EN_Sidebar";

export default {
  name: "EN_TermsAndConditions",
  components: {
    Sidebar
  },
  data() {
    return {
      users: []
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  metaInfo() {
    return {
      title: "Terms of Use and Privacy Policy"
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style type="text/css" scoped>
* {
  color: #6d5a3f;
}

h2 {
  font-size: 18px;
  margin: 40px 0px;
}

h3 {
  font-size: 15px;
  margin: 20px 0px;
}

p {
  margin-bottom: 10px !important;
  font-size: 12px;
  line-height: 18px;
}

ul {
  list-style-type: circle;
}

li {
  font-size: 12px;
}
</style>
